import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk"
import tenantSlice from "./ducks/tenantSlice";
import userSlice from "./ducks/userSlice";
import profileSlice from "./ducks/profileSlice";
import permissionSlice from "./ducks/permissionSlice";
import warehouseSlice from "./ducks/warehouseSlice";
import familySlice from "./ducks/familySlice";
import productSlice from "./ducks/productSlice";
import tagSlice from "./ducks/tagSlice";
import vatSlice from "./ducks/vatSlice";
import supplierSlice from "./ducks/supplierSlice";
import dropshippingSlice from "./ducks/dropshippingSlice";
import poSlice from "./ducks/poSlice";
import companySlice from "./ducks/companySlice";
import pdSlice from "./ducks/pdSlice";
import stockSlice from "./ducks/stockSlice";
import supplierPaymentSlice from "./ducks/supplierPaymentSlice";
import supplierBalanceSlice from "./ducks/supplierBalanceSlice";
import supplierInvoiceSlice from "./ducks/supplierInvoiceSlice";
import importSupplierSlice from "./ducks/importSupplierSlice";
import nomenclatureSlice from "./ducks/nomenclatureSlice";
import importProductSlice from "./ducks/importProductSlice";
import certificateSlice from "./ducks/certificateSlice";
import transitaireSlice from "./ducks/transitaireSlice";
import forwarderSlice from "./ducks/forwarderSlice";
import dossierSlice from "./ducks/dossierSlice";
import proformalInvoiceSlice from "./ducks/proformalInvoiceSlice";
import importEngagementSlice from "./ducks/importEngagementSlice";
import commercialInvoiceSlice from "./ducks/commercialInvoiceSlice";
import packingListSlice from "./ducks/packingListSlice";
import forworderRequestSlice from "./ducks/forworderRequestSlice";
import forwarderQuotationSlice from "./ducks/forwarderQuotationSlice";
import blSlice from "./ducks/blSlice";
import dumSlice from "./ducks/dumSlice";
import FicheLiquidationSlice from "./ducks/FicheLiquidationSlice";
import paymentTicketSlice from "./ducks/paymentTicketSlice";
import localInvoiceSlice from "./ducks/localInvoiceSlice";
import bankTransferOrderSlice from "./ducks/bankTransferOrderSlice";
import bankSlice from "./ducks/bankSlice";
import importPaymentSlice from "./ducks/importPaymentSlice";
import simulationSlice from "./ducks/simulationSlice";
import receptionSlice from "./ducks/receptionSlice";
import trackSlice from "./ducks/trackSlice";
import importDashboardSlice from "./ducks/importDashboardSlice";
import salesPersonSlice from "./ducks/salesPersonSlice";
import salesCustomerSlice from "./ducks/salesCustomerSlice";
import salesOrderSlice from "./ducks/salesOrderSlice";
import salesPaymentSlice from "./ducks/salesPaymentSlice";
import salesTransactionSlice from "./ducks/salesTransactionSlice";
import salesCustomerBalanceSlice from "./ducks/salesCustomerBalanceSlice";
import salesCustomerPrevBalance from "./ducks/salesCustomerPrevBalance";
import salesDefaultBalanceSlice from "./ducks/salesDefaultBalanceSlice";
import salesLoadingSlice from "./ducks/salesLoadingSlice";
import salesDeliverySlice from "./ducks/salesDeliverySlice";
import salesCollectionSlice from "./ducks/salesCollectionSlice";
import bankCollectionSlice from "./ducks/bankCollectionSlice";
import transactionMovmtSlice from "./ducks/transactionMovmtSlice";
import inventaireSlice from "./ducks/inventaireSlice";
import defectsSlice from "./ducks/defectsSlice";
import commentsSlice from "./ducks/CommentsSlice";
import dueDateSlice from "./ducks/dueDateSlice";
import ProgressStatusSlice from "./ducks/ProgressStatusSlice";
import PointOfSalesSlice from "./ducks/PointOfSalesSlice";
import BillofMaterialSlice from "./ducks/BillofMaterialSlice";

const reducer = combineReducers({
    tenant: tenantSlice,
    user: userSlice,
    profile: profileSlice,
    permission: permissionSlice,
    warehouse: warehouseSlice,
    family: familySlice,
    product: productSlice,
    tag: tagSlice,
    vat: vatSlice,
    supplier: supplierSlice,
    dropshipping: dropshippingSlice,
    po: poSlice,
    company: companySlice,
    pd: pdSlice,
    stock: stockSlice,
    payment: supplierPaymentSlice,
    balance: supplierBalanceSlice,
    invoice: supplierInvoiceSlice,
    importSupplier: importSupplierSlice,
    nomenclature: nomenclatureSlice,
    importProduct: importProductSlice,
    certificate: certificateSlice,
    transitaire: transitaireSlice,
    forwarder: forwarderSlice,
    dossier: dossierSlice,
    proformalInvoice: proformalInvoiceSlice,
    importEngagement: importEngagementSlice,
    commercialInvoice: commercialInvoiceSlice,
    packingList: packingListSlice,
    forwarderRequest: forworderRequestSlice,
    forwarderQuotation: forwarderQuotationSlice,
    bl: blSlice,
    dum: dumSlice,
    ficheLiquidation: FicheLiquidationSlice,
    paymentTicket: paymentTicketSlice,
    localInvoice: localInvoiceSlice,
    bankTranfserOrder: bankTransferOrderSlice,
    bank: bankSlice,
    importPayment: importPaymentSlice,
    simulation: simulationSlice,
    reception: receptionSlice,
    track: trackSlice,
    importDashboard: importDashboardSlice,
    salesPerson: salesPersonSlice,
    salesCustomer: salesCustomerSlice,
    salesOrder: salesOrderSlice,
    salesPayment: salesPaymentSlice,
    salesTransaction: salesTransactionSlice,
    customerBalance: salesCustomerBalanceSlice,
    customerPrevBalance: salesCustomerPrevBalance,
    defaultedBalance: salesDefaultBalanceSlice,
    salesLoading: salesLoadingSlice,
    salesDelivery: salesDeliverySlice,
    salesCollection: salesCollectionSlice,
    bankCollection: bankCollectionSlice,
    transactionMovement: transactionMovmtSlice,
    inventaire: inventaireSlice,
    defects: defectsSlice,
    com: commentsSlice,
    dueDate: dueDateSlice,
    ProgressStatus: ProgressStatusSlice,
    PointOfSales :PointOfSalesSlice,
    billOfMaterial :BillofMaterialSlice
})

export const makeStore = (initialState?: any) => configureStore({
    reducer,
    middleware:
        (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),

    preloadedState: initialState
})

const store = makeStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export default store
